.wds {
  form {
    width: 100%;
    margin: 0 0 0.6em 0;
  }

  .wds__form-error-message {
    color: RGB(var(--wds__error-RGB));
    font-size: 0.8em;
    line-height: 1.2;
    margin-top: .2em;
  }

  button, a.wds__button-link {
    border: 0;
    background-color: RGB(var(--wds__accent-RGB));
    color: RGB(var(--wds__on-accent-RGB));
    border-radius: $wds__radius;
    padding: calc(var(--wds__density) * 1em / 3) calc(var(--wds__density) * 1em) calc(var(--wds__density) * 1em / 2.5);
    line-height: var(--wds__density);
    white-space: nowrap;
    cursor: pointer;

    &.size-XS {
      font-size: .6em;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      padding-top: calc(var(--wds__density) * 1em / 2.5);
    }

    &.size-S {
      font-size: .8em;
    }

    &.size-M {} //default

    &.size-L {
      font-size: 1.5em;
      padding: calc(var(--wds__density)*1em/5) calc(var(--wds__density)*1em/1.5) calc(var(--wds__density)*1em/4); //мб не 5, а 4.5
    }

    &.size-XL {
      font-size: 2.5em;
      padding: calc(var(--wds__density)*1em/5) calc(var(--wds__density)*1em/1.5) calc(var(--wds__density)*1em/4);
    }

    &:hover {
      background-color: rgba(var(--wds__accent-RGB), .8);
    }

    &:focus {
      box-shadow: 0 0 0 5px RGB(var(--wds__outline-RGB));
      outline: none;
    }

    &:disabled {
      opacity: .15;
      cursor: default;
      &:hover {
        background-color: RGB(var(--wds__accent-RGB));
      }
    }
  }

  a {
    &.wds__button-link {
      display: inline-block;
    }
  }

  .wds__form-field {
    margin-bottom: calc(var(--wds__density) * 1em);
  }

  input, textarea {
    width: 100%;
    padding: calc(var(--wds__density) * 1em / 3) calc(var(--wds__density) * 1em) calc(var(--wds__density) * 1em / 2.5);
    padding-left: calc(var(--wds__density) * 1em / 2);
    border: 1px solid RGB(var(--wds__text-RGB));
    border-bottom: 2px solid RGB(var(--wds__text-RGB));
    border-radius: $wds__radius;
    background: transparent;
    color: RGB(var(--wds__text-RGB));

    &.size-XS {
      font-size: .6em;
    }

    &.size-S {
      font-size: .8em;
    }

    &.size-M {} //default

    &.size-L {
      font-size: 1.5em;
      padding-top: calc(var(--wds__density)*1em/5);
      padding-bottom: calc(var(--wds__density)*1em/4);
    }

    &.size-XL {
      font-size: 2.5em;
      padding-top: calc(var(--wds__density)*1em/5);
      padding-bottom: calc(var(--wds__density)*1em/4);
    }

    &.wds__input-error-state {
      border-bottom: 2px solid RGB(var(--wds__error-RGB));
    }

    &:focus {
      box-shadow: 0 0 0 5px RGB(var(--wds__outline-RGB));
      outline: none;
    }

    &:disabled {
      opacity: .35;
      cursor: default;
    }
    
    &.wds__input-text-line {
      display: inline-block;
      width: 100%;
      margin: .5em 0 1em 0;
      padding-left: 0;
      border: 0;
      border-radius: .3em .3em 0 0;
      background: transparent;
      color: RGB(var(--wds__text-RGB));
      border-bottom: 2px solid RGB(var(--wds__text-RGB));

      &:active {
        border-bottom: 2px solid RGB(var(--wds__text-RGB));
        border-radius: $wds__radius;
      }

      &::placeholder {
        color: rgba(var(--wds__text-RGB), .6);
      }

      &:focus {
        border-radius: $wds__radius;
      }

      &:disabled {
        opacity: .35;
        cursor: default;
      }
    }
  }

  textarea {
    height: 4em;
    resize: none;
    overflow: auto;

    &.wds__input-text-line {
      margin: 0em 0 1em 0;
      height: 2.5em;
    }
  }

  label {
    display: inline-block;
    margin-bottom: .2em;
  }

  .wds__checkbox {
    display: flex;

    label {
      display: inline-block;
      margin-top: -.11em;
    }

    input[type='checkbox'] {
      -webkit-appearance: none;
      display: inline-block;
      position: relative;
      width: 1em; height: 1em;
      border: 1px solid rgba(var(--wds__text-RGB), .4);
      border-radius: $wds__radius-S;
      margin-right: .5em;
      padding: 0;
      vertical-align: baseline;
      cursor: pointer;
      flex-shrink: 0;
      overflow: hidden;
  
      &:hover {
        border: 1px solid RGB(var(--wds__text-RGB));
      }
  
      &:checked {
        border: 1px solid rgba(var(--wds__accent-RGB), 1);
        background-color: RGB(var(--wds__accent-RGB));
        
        
        &:before {
          content:"";
          position: absolute;
          width: .4em;
          height: .6em;
          border-right: 2px solid RGB(var(--wds__on-accent-RGB));
          border-bottom: 2px solid RGB(var(--wds__on-accent-RGB));
          left: .25em;
          top: .025em;
          transform: rotate(45deg);

          @media #{$wds__mobile} {
            left: .20em;
            top: .08em;
          }
        }
  
        &:hover {
          background-color: rgba(var(--wds__text-RGB), .8);
          border: 1px solid rgba(var(--wds__accent-RGB), .8);
        }
      }
      
      &:disabled {
        opacity: .35;
        cursor: default;
        
        &:hover {
          border: 1px solid rgba(var(--wds__text-RGB), .4);
        }
      }
  
      &:focus {
        box-shadow: 0 0 0 5px RGB(var(--wds__outline-RGB));
        outline: none;
      }
    }
  }
  
  .wds__radio {
    label {
      display: inline-block;
      vertical-align: top;
      
    }

    input[type='radio'] {
      margin-right: .3em;
      margin-top: .13em;
      margin-bottom: .6em;
      padding: 0;
      -webkit-appearance: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      width: 1em; height: 1em;
      border: 1px solid rgba(var(--wds__text-RGB), .4);
      border-radius: 50%;
      
      
      
      &:hover {
        border: 1px solid RGB(var(--wds__text-RGB));
      }

      &:checked {
        border: 1px solid rgba(var(--wds__accent-RGB), 1);
        background-color: RGB(var(--wds__accent-RGB));

        &:after {
          content: '';
          width: .3em;
          height: .3em;
          background-color: RGB(var(--wds__on-accent-RGB));
          border-radius: 50%;
          position: absolute;
          left: .3em;
          top: .3em;
          color: RGB(var(--wds__on-accent-RGB));
          flex-shrink: 0;
        }

        &:hover {
          cursor: default;
        }
      }
      
      &:disabled {
        opacity: .35;
        cursor: default;
        
        &:hover {
          border: 1px solid rgba(var(--wds__text-RGB), .4);
        }
      }

      &:focus {
        box-shadow: 0 0 0 5px RGB(var(--wds__outline-RGB));
        outline: none;
      }
    }

    
  }

  .wds__radiogroup-toggle {
    display: flex;
    flex-direction: row;
    border-radius: $wds__radius;

    .wds__radiogroup-option {
      white-space: nowrap;
      
      input[type='radio'] {
        display: none;

        &:checked + label {
          background-color: RGB(var(--wds__accent-RGB));
          color: RGB(var(--wds__on-accent-RGB));
        }

        &:focus {
          box-shadow: 0 0 0 5px RGB(var(--wds__outline-RGB));
          outline: none;
        }
      }

      label {
        padding: calc(var(--wds__density) * 1em / 3) calc(var(--wds__density) * 1em) calc(var(--wds__density) * 1em / 2.5);
        background-color: rgba(var(--wds__text-RGB), .1);
        cursor: pointer;

        &:hover {
          background-color: rgba(var(--wds__text-RGB), .15);
        }
      }

      &:first-child {
        label {
          border-radius: $wds__radius 0 0 $wds__radius;
        }
      }

      &:last-child {
        label {
          border-radius: 0 $wds__radius $wds__radius 0;
        }
      }
    }

    &:disabled {
      opacity: .35;
      cursor: default;
      
      &:hover {
        label {
          background-color: rgba(var(--wds__text-RGB), .1);
        }
      }
    }
    
  }

  table {
    width: 100%;
    border-spacing: 0;

    th {
      text-align: left;
      padding: .5em 0;
      border-bottom: 2px solid RGB(var(--wds__accent-RGB));
    }

    td {
      padding: calc(var(--wds__density) * 0.6em) 0;
      border-bottom: 1px solid rgba(var(--wds__text-RGB), .15);
    }

    tr {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }

  .pswp {
    button {
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
    }
  }
}
