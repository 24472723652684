$wds__mobile: '(max-width: 767px)';
$wds__desktop: '(min-width: 768px)';

:root {
    --wds__density: 1.3;
}

$wds__radius: 0.3em !default;

$wds__radius-XS: calc(#{$wds__radius} / 3);
$wds__radius-S: calc(#{$wds__radius} / 2);
$wds__radius-XL: calc(#{$wds__radius} * 2);
$wds__radius-XL: 50%;

$wds__images-path: '/images' !default;
