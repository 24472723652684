.system__sidebar {
  position: fixed;
  width: 13em;
  top: 0;
  left: 0;
  bottom: 0;
  @include wds__default-padding;
  z-index: 10000;

  @media #{$wds__mobile} {
    display: none;
  }

  .system__sidebar-logos {
    display: flex;
    margin-left: -0.1em;

    .system__sign {
      position: relative;
      margin-right: -1.5em;
      margin-bottom: 1em;
      z-index: 1;
      border-bottom: 0;

      &:hover {
        z-index: 2;
      }
      img {
        width: auto;
        height: 2.5em;
      }

      &.rounded {
        overflow: hidden;
        border-radius: 50%;
      }
    }
  }

  .system__sidebar-nav {
    margin-bottom: 0.8em;

    .system__sidebar-nav-link {
      margin-bottom: 0.3em;
      font-weight: bold;

      &.inner {
        font-weight: normal;
        position: relative;
        padding-left: calc(var(--wds__density) * 0.6em);

        &:before {
          position: absolute;
          left: -0.1em;
          content: '⋅ ';
        }
      }
    }
  }
}

h1.huge-wds-title {
  font-size: 15em;
  line-height: 0.8;
  letter-spacing: -0.03em;

  span {
    display: inline-block;
    position: relative;
    color: RGB(var(--wds__on-accent-RGB));

    &::before {
      content: '';
      position: absolute;
      top: -0.05em;
      left: -0.02em;
      right: -0.22em;
      bottom: -0.03em;

      // height: 1em;

      background-color: RGB(var(--wds__accent-RGB));
      border-radius: 0.13em;
      z-index: -1;
    }
  }
}

.brandplatform-table {
  .brand-rule {
    margin-bottom: 2em;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid rgba(var(--wds__text-RGB), 0.1);
    padding-top: 0.5em;

    .rule-no {
      width: 50%;
      padding-right: 3%;
      padding-bottom: 0.5em;
    }

    .rule-yes {
      width: 50%;
      padding-right: 3%;
      padding-bottom: 0.5em;
    }
    .rule-comment {
      width: 100%;
      padding-right: 3%;
    }
  }
}
