:root {
  --wds__background-RGB: 255, 255, 255;
  --wds__text-RGB: 5, 5, 5;
  --wds__accent-RGB: 0, 0, 0;
  --wds__main-RGB: var(--wds__accent-RGB);
  --wds__surface-RGB: 245, 245, 245;
  --wds__error-RGB: 240, 84, 95;
  --wds__success-RGB: 84, 180, 140;
  --wds__mark-RGB: 255, 248, 128;
  --wds__code-RGB: 200, 0, 0;

  --wds__on-main-RGB: var(--wds__background-RGB);
  --wds__on-accent-RGB: var(--wds__background-RGB);
  --wds__on-surface-RGB: var(--wds__text-RGB);

  --wds__link-RGB: 0, 100, 240;
  --wds__hover-RGB: 0, 220, 0;
  --wds__outline-RGB: var(--wds__surface-RGB);
}
