.right-shifted {
  @media #{$wds__desktop} {
    padding-left: 12em;
  }
}

.future-card {
  width: 98%;

  .future-card-image {
    padding-bottom: 63%;
    background-size: cover;
    background-position: 100% 30%;
    border-radius: 0.4em;
  }

  .future-card-text-plate {
    padding: 0.8em 2em 2em 0;
  }
}

.palette {
  display: flex;
  flex-wrap: wrap;

  .palette-bar {
    width: 25%;
    height: 5em;
    padding: calc(var(--wds__density) * 0.4em) calc(var(--wds__density) * 0.5em);

    @media #{$wds__mobile} {
      width: 50%;
    }
  }
}

.logos-palette {
  display: flex;
  flex-wrap: wrap;

  .inverted-logo-bar {
    width: 50%;
    height: 10em;
    background-image: url('/assets/images/winvestor-inverted-logo-ru.svg');
    background-repeat: no-repeat;
    background-position: center 45%;
    background-size: 65% auto;
  }
}

.font-letter {
  font-size: 2.5em;
  line-height: calc(var(--wds__density) / 1.2);
  margin-bottom: 0.5em;

  &.regular {
    font-weight: 400;
  }
  &.medium {
    font-weight: 500;
  }
  &.bold {
    font-weight: 700;
  }

  @media #{$wds__mobile} {
    font-size: 2.2em;
  }
}
