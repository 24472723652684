.wds__divider-line {
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(var(--wds__text-RGB), 0.2);
  margin-bottom: calc(var(--wds__density) * 1em);
}

.wds__admin-form {
  width: 100%;
}

.wds__error-toast,
.wds__success-toast {
  position: relative;
  display: block;
  width: 20em;
  padding: $wds__padding;
  border-radius: $wds__radius;
  margin-bottom: calc(var(--wds__density) * 1em);
}

.wds__error-toast {
  background-color: rgba(var(--wds__error-RGB), 0.15);
  color: RGB(var(--wds__error-RGB));
}

.wds__success-toast {
  background-color: rgba(var(--wds__success-RGB), 0.15);
  color: RGB(var(--wds__success-RGB));
}
