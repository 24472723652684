:root {
  --fluid-min-width: 1000;
  --fluid-max-width: 1600;
  --fluid-screen: 100vw; // шрифт растягивается с этим параметром. если его зафиксировать в пикс, не растягивается

  --type-scale-desktop: 1.5; //коэффициент увеличения шрифта десктоп
  --font-size-min: 16.00; //размер шрифта мин десктоп
  --font-size-max: 20.00; //размер шрифта макс десктоп
  
  --type-scale-mobile: 1.333; //коэффициент увеличения шрифта телефон
  --font-size-min-mobile: 17.00; //размер шрифта мин телефон
  --font-size-max-mobile: 19.00; //размер шрифта макс телефон

  @media #{$wds__desktop} {
    --f-0-min: var(--font-size-min);
    --f-0-max: var(--font-size-max);
    --type-scale: var(--type-scale-desktop);
  }

  @media #{$wds__mobile} {
    --f-0-min: var(--font-size-min-mobile);
    --f-0-max: var(--font-size-max-mobile);
    --type-scale: var(--type-scale-mobile);
  }

  @include typoVariables();
}

// на ширине экрана меньше 1000px шрифт не уменьшается
@media screen and (max-width: 1000px) {
  :root {
    --fluid-screen: calc(var(--fluid-min-width) * 1px);

    @include typoVariables();
  }
}

// на ширине больше 1350 шрифт не увеличивается
@media screen and (min-width: 1600px) {
  :root {
    --fluid-screen: calc(var(--fluid-max-width) * 1px);

    @include typoVariables();
  }
}

@media #{$wds__mobile} {
  :root {
    --fluid-min-width: 320;
    --fluid-max-width: 768;
    --fluid-screen: 100vw;

    @include typoVariables();
  }
}



.wds {
  section {
    margin-top: calc(var(--wds__density) * 1.5em);
  
    @media #{$wds__mobile} {
      margin-top: calc(var(--wds__density) * 1em / 2);
    }
  }
  
  a {
    color: RGB(var(--wds__link-RGB));
    border-bottom: 1px solid rgba(var(--wds__link-RGB), .2);
    text-decoration: none;

    &:hover {
      color: RGB(var(--wds__hover-RGB));
      border-bottom: 1px solid rgba(var(--wds__hover-RGB), .2);
    }
  }

  p {
    margin-top: 0;
    margin-bottom: calc(var(--wds__density) * 0.4em);
  }

  .wds__lead {
    font-size: 1.2em;
    font-weight: 500;
    line-height: var(--wds__density);
    margin-bottom: calc(var(--wds__density) * 0.4em);

    @media #{$wds__mobile} {
      font-size: 1em;
      line-height: var(--wds__density);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 600;

    // Стиль зачеркивания заголовков маркером
    mark {
      background: none;
      box-shadow: inset 0 -0.15em RGB(var(--wds__background-RGB)),
        inset 0 -0.5em RGB(var(--wds__mark-RGB));
    }
  }

  h1 {
    font-size: var(--step-3);
    line-height: calc(var(--wds__density) / 1.3);
    margin-bottom: 0.4em;
  }

  h2 {
    font-size: var(--step-2);
    line-height: calc(var(--wds__density) / 1.2);
    margin-bottom: 0.5em;
  }

  h3 {
    font-size: var(--step-1);
    line-height: calc(var(--wds__density) / 1.1);
    margin-bottom: 0.6em;
  }

  h4 {
    font-size: var(--step-0);
    line-height: var(--wds__density);
    margin-bottom: 0.6em;
  }

  h5 {
    font-size: calc(var(--step-0) * 0.8);
    line-height: var(--wds__density);
    margin-bottom: 0.7em;
  }

  h6 {
    font-size: calc(var(--step-0) * 0.6);
    text-transform: uppercase;
    line-height: var(--wds__density);
    margin-bottom: 0.7em;
  }

  em,
  i {
  }

  strong,
  b {
    font-weight: 600;
  }

  mark {
    color: RGB(var(--wds__text-RGB));
    background-color: RGB(var(--wds__mark-RGB));
  }
  

  ul,
  ol {
    margin: 0 0 calc(var(--wds__density) * 0.75em) 0;
    padding: 0;

    li {
      position: relative;
      margin-bottom: calc(var(--wds__density) * 0.5em);
    }
  }

  ul {
    li {
      list-style-type: none;
      padding-left: calc(var(--wds__density) * 0.75em);

      &:before {
        position: absolute;
        left: 0;
        content: '⋅ ';
      }
    }

    &.checked {
      li {
        &:before {
          content: ' ';
          position: absolute;
          left: 0;
          top: .45em;
          background-image: url("/assets/images/icon-check-mark.svg");
          background-size: contain;
          background-repeat: no-repeat;
          width: 0.7em;
          height: 0.7em;
        }
      }
    }
  }

  ol {
    li {
      list-style: decimal inside none;
    }
  }

  blockquote {
    font-size: 1.4em;
    line-height: var(--wds__density);
    margin: 0;
    padding-left: calc(var(--wds__density) * 0.75em);
    margin-bottom: calc(var(--wds__density) * 1em);
  }

  aside {
    margin: 0;
    margin-bottom: calc(var(--wds__density) * 0.75em);
    background: RGB(var(--wds__surface-RGB));
    padding: calc(var(--wds__density) * 0.75em);
    padding-right: calc(var(--wds__density) * 2em);
    border-radius: $wds__radius;
  }

  code {
    font-weight: 500;
    padding: $wds__micro-padding;
    background: RGB(var(--wds__surface-RGB));
    color: RGB(var(--wds__code-RGB));
  }

  a.button {
  }

  figure {
    margin: 0 0 calc(var(--wds__density) * 0.5em) 0;

    &.wds__figure__XXS {
      width: 25%;
    }
    &.wds__figure__XS {
      width: 50%;
    }
    &.wds__figure__S {
      width: 75%;
    }

    figcaption {
      margin-top: calc(var(--wds__density) * 0.25em);
      font-size: 0.8em;
      line-height: var(--wds__density);
      opacity: 0.7;
    }
  }

  details {
    padding: .5em;
    cursor: pointer;
    // border: 1px solid rgba(var(--wds__text-RGB), 0.1);
    border-radius: $wds__radius;

    summary {
      padding-bottom: $wds__micro-padding;
    }
  }

  .wds__error-color {
    color: RGB(var(--wds__error-RGB));
  }

  .wds__success-color {
    color: RGB(var(--wds__success-RGB));
  }

  .wds__marginal-note {
  }

  .wds__footnote {
  }

  .wds__label {
    display: inline-block;
    padding: $wds__micro-padding;
    background-color: RGB(var(--wds__accent-RGB));
    color: RGB(var(--wds__on-accent-RGB));
    border-radius: $wds__radius-S;

    &.wds__label-outline {
      background-color: RGB(var(--wds__on-accent-RGB));
      color: RGB(var(--wds__text-RGB));
      border: 2px solid RGB(var(--wds__accent-RGB));
    }

    &.wds__label-S {
      font-size: .9em;
    }
  }
}
