body {
  min-width: 1100px;
  max-width: 2000px;

  @media #{$wds__mobile} {
    min-width: auto;
  }
}

.design-header {
  @include wds__default-padding;
}

.design-logo {
  img {
    width: auto;
    height: 2.5em;
  }
}

.brandbook-section {
  display: block;
  margin-right: 2%;

  height: 20em;

  background-color: RGB(var(--wds__surface-RGB));
  padding: calc(var(--wds__density) * 0.75em);
  padding-right: 3em;
  border-radius: $wds__radius;
  margin-bottom: calc(var(--wds__density) * 0.75em);

  h2 {
    margin-top: 0.2em;
    line-height: 1;
  }

  .wds__label {
    background-color: RGB(var(--wds__link-RGB));
  }

  &:hover {
    .wds__label {
      background-color: RGB(var(--wds__hover-RGB));
    }
  }
}

.plug {
  height: 5em;
  width: 100%;
}

.wds {
  .theme-button {
    position: fixed;
    top: 1em;
    right: 1em;
    cursor: pointer;
    z-index: 999999;
    border-bottom: 0;

    &:hover {
      opacity: 0.9;
      border-bottom: 0;
    }
  }
}
